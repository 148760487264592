@if (
  {
    applyActionStatus: applyActionStatus$ | async,
    promocodeReward: promocodeReward$ | async
  };
  as data
) {
  <div
    class="promo-wrapper"
    [formGroup]="promocodeForm"
    [class]="data.applyActionStatus"
  >
    <div class="tw-text-base tw-font-semibold tw-text-gray-100">
      @if (data.applyActionStatus !== actionStatusEnum.ERROR) {
        {{ headerText | translate }}
      } @else {
        {{ 'LOCAL.PROMOCODE_WIDGET.PROMOCODE_CHECK' | translate }}
      }
    </div>
    <div class="promo-input">
      <app-ui-input
        [prefixIcon]="showIcon(data.applyActionStatus)"
        formControlName="code"
        [canPaste]="true"
        [placeholder]="'LOCAL.PROMOCODE_WIDGET.INPUT_PLACEHOLDER' | translate"
      >
        <div>
          @switch (data.applyActionStatus) {
            @case (actionStatusEnum.ERROR) {
              <div>
                <button
                  class="btn promo--activate-button close"
                  (click)="resetPromocode()"
                >
                  <mat-icon svgIcon="x--promo"></mat-icon>
                </button>
              </div>
            }
            @case (actionStatusEnum.SUCCESS) {
              <div>
                <button
                  class="btn promo--activate-button arrow"
                  (click)="activatePromocode()"
                >
                  <mat-icon svgIcon="arrow-right"></mat-icon>
                </button>
              </div>
            }
            @case (actionStatusEnum.DISPATCH) {
              <div>
                <button class="btn promo--activate-button">
                  <app-ui-spinner-loader></app-ui-spinner-loader>
                </button>
              </div>
            }
            @default {
              <div>
                <button
                  class="btn promo--activate-button arrow"
                  (click)="activatePromocode()"
                >
                  <mat-icon svgIcon="arrow-right"></mat-icon>
                </button>
              </div>
            }
          }
        </div>
      </app-ui-input>
    </div>
    @if (data.applyActionStatus) {
      <div class="promo-description">
        @if (data.applyActionStatus === actionStatusEnum.DEFAULT) {
          <div class="description-default">
            @if (!isBonusCodeEntered()) {
              {{ 'PROMOCODE_WIDGET.DEFAULT_CODE_MESSAGE' | translate }}
              <a
                (click)="useDefPromo()"
                class="default-code"
                >{{ defaultPromocode }}</a
              >
            }
          </div>
        }
        @if (data.applyActionStatus === actionStatusEnum.SUCCESS) {
          <div class="description-bonus">
            @if (data.promocodeReward; as reward) {
              {{ 'LOCAL.PROMOCODE_WIDGET.PROMOCODE_BONUS' | translate }}
              @if (reward.bonusPercent) {
                {{ reward.bonusPercent }}
              }
              @if (reward.bonusCoins) {
                + <app-currency [value]="reward.bonusCoins"></app-currency>
              }
              @if (reward.bonusCases) {
                (+ {{ reward.bonusCases }} {{ 'LOCAL.PROMOCODE_WIDGET.PROMOCODE_BONUS_CASE' | translate }})
              }
            }
          </div>
        }
        @if (data.applyActionStatus === actionStatusEnum.ERROR) {
          <div class="description-error">
            {{ errorMessageConstructor(errorMessage) | translate }}
          </div>
        }
      </div>
    }
    @if (data.applyActionStatus === actionStatusEnum.SUCCESS) {
      <div class="refill-btn">
        <button
          class="btn btn-inversion-yellow--design"
          (click)="onRefill()"
        >
          {{ 'LOCAL.PROMOCODE_MODAL.REFILL_BTN' | translate }}
        </button>
      </div>
    }
  </div>
}
