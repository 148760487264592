@if ({ applyActionStatus: applyActionStatus$ | async }; as data) {
  <form
    class="promocode__wrapper"
    [formGroup]="promocodeForm"
    [class]="activeClasses(promoStatus, isCompact)"
  >
    @if (!isCompact) {
      <div class="promocode__section">
        <mat-icon
          class="promocode__label-icon"
          [svgIcon]="'promo-ticket'"
        ></mat-icon>
        <div class="promocode__label-wrapper">
          <div class="promocode__label-text promocode__label-text_bold">
            @if (promoStatus === promoStatusEnum.SUCCESS || promoStatus === promoStatusEnum.DEFAULT) {
              {{ 'LOCAL.PROMOCODE_WIDGET.MAINLABEL.' + promoStatus | uppercase | translate }}
            } @else {
              {{ errorMessageConstructor(errorMessage) | uppercase | translate }}
            }
          </div>
          <div class="promocode__label-text promocode__label-text_regular">
            @switch (promoStatus) {
              @case (promoStatusEnum.DEFAULT) {
                <span>{{ 'LOCAL.PROMOCODE_WIDGET.PROMOCODE_LABEL' | translate }} </span>
                <a
                  class="promocode__label-text promocode__label-text_green promocode__label-text_underline"
                  (click)="useDefPromo()"
                >
                  {{ 'LOCAL.PROMOCODE_WIDGET.USE_OUR_CORE' | translate }}
                </a>
              }
              @case (promoStatusEnum.ERROR) {
                <span>
                  {{ 'LOCAL.PROMOCODE_WIDGET.PROMOCODE_CHECK' | translate }}
                </span>
              }
              @case (promoStatusEnum.SUCCESS) {
                @if (bonusAmountConstructor(reward); as bonus) {
                  <span class="promocode__label-text promocode__label-text_green">
                    {{ 'LOCAL.PROMOCODE_WIDGET.PROMOCODE_BONUS' | translate }}
                    @if (bonus.bonusPercent) {
                      {{ bonus.bonusPercent }}
                    }
                    @if (bonus.bonusCoins) {
                      + {{ bonus.bonusCoins | appCurrency }}
                    }
                    @if (bonus.bonusCases) {
                      (+ {{ bonus.bonusCases }} {{ 'LOCAL.PROMOCODE_WIDGET.PROMOCODE_BONUS_CASE' | translate }})
                    }
                  </span>
                }
              }
            }
          </div>
        </div>
      </div>
      <div class="promocode__section promocode__section_form">
        <app-ui-input
          [class]="promoStatus"
          [prefixIcon]="showIcon(promoStatus)"
          formControlName="code"
          [canPaste]="true"
          [placeholder]="'LOCAL.PROMOCODE_WIDGET.INPUT_PLACEHOLDER' | translate"
        >
        </app-ui-input>
        <button
          [disabled]="isButtonDisabled(promocodeForm.invalid, data.applyActionStatus)"
          class="btn btn-semi-round md btn-primary-outline-green--design promocode__activate-button"
          (click)="activatePromocode()"
        >
          {{ 'LOCAL.PROMOCODE_WIDGET.APPLY' | translate }}
        </button>
      </div>
    } @else {
      <div class="promocode__section">
        <div class="promocode__label-wrapper promocode__label-wrapper_compact">{{ 'LOCAL.PROMOCODE_WIDGET.TITLE' | translate }}:</div>
      </div>
      <div class="promocode__section promocode__section_form">
        <app-ui-input
          [class]="promoStatus"
          [prefixIcon]="showIcon(promoStatus)"
          formControlName="code"
          [canPaste]="true"
          [placeholder]="'LOCAL.PROMOCODE_WIDGET.INPUT_PLACEHOLDER' | translate"
        >
          @if (promocodeForm.dirty) {
            <button
              [disabled]="isButtonDisabled(promocodeForm.invalid, data.applyActionStatus)"
              class="btn promocode__activate-button promocode__activate-button_clear"
              (click)="activatePromocode()"
            >
              {{ 'LOCAL.PROMOCODE_WIDGET.APPLY' | translate }}
            </button>
          } @else {
            @if (bonusAmountConstructor(reward); as bonus) {
              <div class="bonus-container">
                @if (bonus.bonusCases) {
                  <div class="bonus-container__case">
                    {{ 'LOCAL.PROMOCODE_WIDGET.PROMOCODE_BONUS_CASE' | translate }}
                  </div>
                }
                @if (bonus.bonusPercent) {
                  <div class="bonus-container__amount">
                    {{ bonus.bonusPercent }}
                  </div>
                }
                @if (bonus.bonusCoins) {
                  <div class="bonus-container__amount">+ {{ bonus.bonusCoins | appCurrency }}</div>
                }
              </div>
            }
          }
        </app-ui-input>
      </div>
      @if (minPayment) {
        <div class="promocode__description">
          {{ 'LOCAL.PAYMENT.REFILL.MONEY_REFILL_DETAILS.FROM_FULL' | translate }}
          ${{ minPayment }}
        </div>
      }
    }
  </form>
}
