import { IPromocodeReward, PromocodeTypes } from '@dev-fast/types';

import { BonusAmount } from './types';

export const bonusAmountConstructorUtil = (reward: IPromocodeReward | null): BonusAmount => {
  const bonusInCoins = reward?.rewardValue.type === PromocodeTypes.MONEY ? reward?.rewardValue.amount : 0;
  const bonusInPercent = reward?.rewardValue.type === PromocodeTypes.PERCENT ? reward?.rewardValue.amount : 0;
  const percentBonus = bonusInPercent > 0 ? `+ ${bonusInPercent}%` : '';
  return {
    bonusCases: reward?.bonusCases,
    bonusPercent: percentBonus,
    bonusCoins: bonusInCoins,
  };
};
export const errorMessageConstructorUtil = (errorMessage: string | undefined): string => {
  if (!errorMessage) {
    return 'LOCAL.PROMOCODE_WIDGET.MAINLABEL.ERROR';
  }
  const errorMessageLower = errorMessage.toLowerCase();
  if (errorMessageLower.includes('not found')) {
    return 'LOCAL.PROMOCODE_WIDGET.MAINLABEL.NOT_FOUND';
  }
  if (errorMessageLower.includes('too many')) {
    return 'LOCAL.PROMOCODE_WIDGET.MAINLABEL.TOO_MANY';
  }
  if (errorMessageLower.includes('you already use')) {
    return 'LOCAL.PROMOCODE_WIDGET.MAINLABEL.ALREADY_USE';
  }
  return errorMessage;
};
