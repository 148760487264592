import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { PromoStatus, StateActionStatus } from '@dev-fast/types';
import { SpinnerLoaderSAComponent, UiInputSAComponent } from '@dev-fast/ui-components';
import { TranslateModule } from '@ngx-translate/core';
import { combineLatest, map, merge, Observable, startWith, Subject, takeUntil, tap } from 'rxjs';

import { CurrencyComponent } from '@app/core/currency';
import { AppCurrencyModule } from '@app/shared/pipes';

import { DEFAULT_PROMO } from '../constants/background.const';
import { PROMOCODE_ENGINE, PromocodeEngine } from '../symbols';
import { BonusAmount, IPromocodeForm } from '../types';
import { bonusAmountConstructorUtil, errorMessageConstructorUtil } from '../utils';

@Component({
  selector: 'app-promocode-mobile',
  standalone: true,
  imports: [
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    UiInputSAComponent,
    SpinnerLoaderSAComponent,
    AppCurrencyModule,
    CurrencyComponent,
    MatIconModule,
    AsyncPipe,
  ],
  templateUrl: './promocode-mobile.component.html',
  styleUrls: ['./promocode-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// Сложно написано, знаю что переписать пока оставил как есть
export class PromocodeMobileComponent implements OnInit, OnDestroy {
  @Input() defaultPromocode = DEFAULT_PROMO;
  @Input() minPayment: number | undefined;
  @Output() closeEvent: EventEmitter<void> = new EventEmitter<void>(false);
  promocodeForm: FormGroup<IPromocodeForm> = new FormGroup<IPromocodeForm>({
    code: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required, Validators.minLength(1)],
    }),
  });
  private destroy$: Subject<void> = new Subject();
  private lastAppliedPromo = '';
  actionStatusEnum: typeof StateActionStatus = StateActionStatus;
  headerText = 'LOCAL.PROMOCODE_WIDGET.MAINLABEL.DEFAULT';
  // статус выполнения диспатча
  applyActionStatus$: Observable<StateActionStatus> = merge(
    combineLatest([this.promocodeService.promocodeStatus$, this.promocodeService.applyActionStatus$]).pipe(
      map(([promoStatus, actionStatus]) => {
        if (promoStatus === PromoStatus.SUCCESS && actionStatus === StateActionStatus.SUCCESS) {
          return StateActionStatus.SUCCESS;
        }
        if (promoStatus === PromoStatus.DEFAULT) {
          return StateActionStatus.DEFAULT;
        }
        if (actionStatus === StateActionStatus.DISPATCH) {
          return StateActionStatus.DISPATCH;
        }
        return promoStatus === PromoStatus.ERROR ? StateActionStatus.ERROR : StateActionStatus.DEFAULT;
      }),
      startWith(StateActionStatus.DEFAULT),
    ),
    this.promocodeForm.controls.code.valueChanges.pipe(map((val) => StateActionStatus.DEFAULT)),
  ).pipe(
    tap((val) => {
      if (val === StateActionStatus.SUCCESS || val === StateActionStatus.DEFAULT) {
        this.headerText = `LOCAL.PROMOCODE_WIDGET.MAINLABEL.${val.toUpperCase()}`;
      }
    }),
  );
  errorMessage: string | undefined;

  // награда за промокод. Приходит всегда
  promocodeReward$: Observable<BonusAmount> = this.promocodeService.promocodeReward$.pipe(
    map((reward) => bonusAmountConstructorUtil(reward)),
  );
  constructor(
    @Inject(PROMOCODE_ENGINE) private promocodeService: PromocodeEngine,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.promocodeService.getCurrentPromo();
    // текущий промокод в стейте
    this.promocodeService.promocode$.pipe(takeUntil(this.destroy$)).subscribe((promocode) => {
      if (promocode) {
        this.promocodeForm.setValue({ code: !promocode.rewarded ? promocode?.code : '' });
      }
      this.cdr.markForCheck();
    });
    // ошибка в стейте после запроса или активации
    this.promocodeService.promocodeError$.pipe(takeUntil(this.destroy$)).subscribe((promocodeError) => {
      this.errorMessage = promocodeError?.message;
      this.cdr.markForCheck();
    });

    // статус в стейте после запроса или активации
    this.promocodeService.promocodeStatus$.pipe(takeUntil(this.destroy$)).subscribe((val) => {
      if (val === PromoStatus.SUCCESS) {
        this.lastAppliedPromo = this.promocodeForm.controls.code.value;
        this.promocodeForm.markAsPristine();
      }
      this.cdr.markForCheck();
    });
    this.promocodeForm.controls.code.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val) => {
      if (val === this.lastAppliedPromo) {
        this.promocodeForm.markAsPristine();
      }
      this.cdr.markForCheck();
    });
  }
  ngOnDestroy(): void {
    this.destroy$.next();
  }
  activatePromocode(): void {
    if (this.promocodeForm.value.code) {
      this.promocodeService.activatePromoCode(this.promocodeForm.value.code);
    }
  }
  resetPromocode(): void {
    this.promocodeForm.reset();
  }
  isBonusCodeEntered(): boolean {
    return this.promocodeForm.controls.code.value === this.defaultPromocode;
  }
  useDefPromo(): void {
    this.promocodeForm.setValue({ code: this.defaultPromocode });
  }

  showIcon(promoStatus: StateActionStatus | null): string | undefined {
    const code = this.promocodeForm.controls.code.value;
    if (code.length) {
      if (promoStatus === StateActionStatus.SUCCESS) {
        return 'checkmark-outline';
      }
      if (promoStatus === StateActionStatus.ERROR) {
        return 'checkmark-outline-error';
      }
    }
    return;
  }
  errorMessageConstructor(errorMessage: string | undefined): string {
    return errorMessageConstructorUtil(errorMessage);
  }
  onClose(): void {
    this.closeEvent.emit();
  }
  onRefill(): void {
    this.onClose();
    // стоит вынести наружу
    this.promocodeService.navigateTo([{ outlets: { modal: ['transaction', 'refill'] } }], {}, { queryParamsHandling: 'preserve' });
  }
}
