import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SpinnerLoaderSAComponent, UiInputSAComponent } from '@dev-fast/ui-components';
import { TranslateModule } from '@ngx-translate/core';

import { P2pNewSetupModalComponent } from './p2p-setup-modal.component';

@NgModule({
  declarations: [P2pNewSetupModalComponent],
  imports: [
    CommonModule,
    MatIconModule,
    TranslateModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    UiInputSAComponent,
    SpinnerLoaderSAComponent,
  ],
  exports: [P2pNewSetupModalComponent],
})
export class P2pSetupModalModule {}
