import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, TypeProvider } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { SelectionInputModule, UiInputSAComponent } from '@dev-fast/ui-components';
import { TranslateModule } from '@ngx-translate/core';

import { AppCurrencyModule } from '@app/shared/pipes';

import { PromocodeComponent } from './promocode.component';
import { PROMOCODE_ENGINE } from './symbols';

@NgModule({
  imports: [CommonModule, SelectionInputModule, TranslateModule, ReactiveFormsModule, AppCurrencyModule, MatIconModule, UiInputSAComponent],
  declarations: [PromocodeComponent],
  exports: [PromocodeComponent],
})
export class PromocodeWidgetModule {
  public static forRoot(engine: TypeProvider): ModuleWithProviders<PromocodeWidgetModule> {
    return {
      ngModule: PromocodeWidgetModule,
      providers: [
        {
          provide: PROMOCODE_ENGINE,
          useClass: engine,
        },
      ],
    };
  }
}
